
<script>
    import validations from "../locales/en/validation.json";

    export default {
        components:{
        },
        name: "validations",
        props: {
            validationName: {
                type: Object,
                required: true
            },
            fieldName:{
                type: String,
                required: true
            }
        },
        data() {
            return {
                validations: validations,
            };
        },
    }
</script>
<template v-if="validationName.$error">
    <div >
        <div class="invalidFeedback" v-for="(index, value) in validationName.$params" :key="value">
            <li v-if="!validationName[value]">{{validations[value][fieldName]}}</li>
        </div>
    </div>
</template>
